import React, { useContext, useState, useEffect } from "react";
import {
    Container,
    Wrapper,
    Logo,
    StyledBurger,
    ParentMenuList,
    ParentMenuItem,
    ChildMenuList,
    ChildMenuItem,
    ParentMenuLink,
    ChildMenuLink,
    RegistrationButton,
    HomePageLink,
} from "./headerComponents";
import logo from "./../../images/header/logoMain.jpg";
import LanguageSelector from "./LanguageSelector";
import { LanguageContext } from "../../containers/Language";

function Header() {
    const { userLanguage, dictionary } = useContext(LanguageContext);
    const hideRegistrationButtonWidth = 480;
    const [isOpen, setIsOpen] = useState(false);
    const [googleFormUrl, setGoogleFormUrl ] = useState(
        "https://docs.google.com/forms/d/e/1FAIpQLSdCcXyVyDeg8rWoyP3HF1FAhIrORLyp9wox1z7cxBX8y0GSyA/viewform?usp=sharing"
    );
    const isRegistrationItemVisible =
        window.innerWidth <= hideRegistrationButtonWidth;

    useEffect(() => {
        setRegistrationUrl(userLanguage);
    }, [userLanguage]);

    function setRegistrationUrl(userLanguage) {
        let url;

        if (userLanguage === "kz") {
            url =
            "https://docs.google.com/forms/d/e/1FAIpQLSdCcXyVyDeg8rWoyP3HF1FAhIrORLyp9wox1z7cxBX8y0GSyA/viewform?usp=sharing";
        } else if (userLanguage === "ru") {
            url =
            "https://docs.google.com/forms/d/e/1FAIpQLSdCcXyVyDeg8rWoyP3HF1FAhIrORLyp9wox1z7cxBX8y0GSyA/viewform?usp=sharing";
        } else {
            url =
            "https://docs.google.com/forms/d/e/1FAIpQLSdCcXyVyDeg8rWoyP3HF1FAhIrORLyp9wox1z7cxBX8y0GSyA/viewform?usp=sharing";
        }

        setGoogleFormUrl(url);
    }

    return (
        <Container>
            <Wrapper>
                <HomePageLink href="/">
                    <Logo src={logo} />
                </HomePageLink>
                <ParentMenuList isOpen={isOpen}>
                    <ParentMenuItem>
                        <ParentMenuLink href="/about-school">
                            {dictionary.header.aboutSchool}
                        </ParentMenuLink>
                        <ChildMenuList>
                            <ChildMenuItem>
                                <ChildMenuLink href="/about-school#mission">
                                    {dictionary.header.mission}
                                </ChildMenuLink>
                                <ChildMenuLink href="/about-school#values">
                                    {dictionary.header.values}
                                </ChildMenuLink>
                                <ChildMenuLink href="/about-school#history">
                                    {dictionary.header.history}
                                </ChildMenuLink>
                                <ChildMenuLink href="/about-school#school">
                                    {dictionary.header.school}
                                </ChildMenuLink>
                                <ChildMenuLink href="/about-school#administration">
                                    {dictionary.header.administration}
                                </ChildMenuLink>
                                <ChildMenuLink href="/about-school#teacher">
                                    {dictionary.header.teacher}
                                </ChildMenuLink>
                                <ChildMenuLink href="/about-school#kurator">
                                    {dictionary.header.kurator}
                                </ChildMenuLink>
                                <ChildMenuLink href="/about-school#kurator">
                                    {dictionary.header.attestation}
                                </ChildMenuLink>
                            </ChildMenuItem>
                        </ChildMenuList>
                    </ParentMenuItem>

                    <ParentMenuItem>
                        <ParentMenuLink href="/education-process">
                            {dictionary.header.learningProcess}
                        </ParentMenuLink>
                        <ChildMenuList>
                            <ChildMenuItem>
                                <ChildMenuLink href="/education-process#classes">
                                    {dictionary.header.classes}
                                </ChildMenuLink>
                                <ChildMenuLink href="/education-process#timetable">
                                    {dictionary.header.timetable}
                                </ChildMenuLink>
                                <ChildMenuLink href="/education-process#assessment">
                                    {dictionary.header.grades}
                                </ChildMenuLink>
                                <ChildMenuLink href="/education-process#edupage">
                                    {dictionary.header.edupage}
                                </ChildMenuLink>
                                <ChildMenuLink href="/education-process#help">
                                    {dictionary.header.help}
                                </ChildMenuLink>
                                <ChildMenuLink href="/education-process#pbl">
                                    {dictionary.header.pbl}
                                </ChildMenuLink>
                                <ChildMenuLink href="/education-process#competition">
                                    {dictionary.header.competition}
                                </ChildMenuLink>
                                <ChildMenuLink href="/education-process#english">
                                    {dictionary.header.english}
                                </ChildMenuLink>
                            </ChildMenuItem>
                        </ChildMenuList>
                    </ParentMenuItem>

                    <ParentMenuItem>
                        <ParentMenuLink href="/educational-work">
                            {dictionary.header.tarbieJumystary}
                        </ParentMenuLink>
                        <ChildMenuList>
                            <ChildMenuItem>
                                <ChildMenuLink href="/educational-work#values">
                                    {dictionary.header.values}
                                </ChildMenuLink>
                                <ChildMenuLink href="/educational-work#sections">
                                    {dictionary.header.sections}
                                </ChildMenuLink>
                                <ChildMenuLink href="/educational-work#book">
                                    {dictionary.header.book}
                                </ChildMenuLink>
                                <ChildMenuLink href="/educational-work#parents">
                                    {dictionary.header.parents}
                                </ChildMenuLink>
                                <ChildMenuLink href="/educational-work#activities">
                                    {dictionary.header.activities}
                                </ChildMenuLink>
                                <ChildMenuLink href="/educational-work#plan">
                                    {dictionary.header.plan}
                                </ChildMenuLink>
                                <ChildMenuLink href="/educational-work#internat">
                                    {dictionary.header.internat}
                                </ChildMenuLink>
                            </ChildMenuItem>
                        </ChildMenuList>
                    </ParentMenuItem>

                    <ParentMenuItem>
                        <ParentMenuLink href="/admission">
                            {dictionary.header.admission}
                        </ParentMenuLink>
                        <ChildMenuList>
                            <ChildMenuItem>
                            <ChildMenuLink href="/admission#steps">
                                    {dictionary.header.stepslittle}
                                </ChildMenuLink>
                                <ChildMenuLink href="/admission#steps">
                                    {dictionary.header.stepsbig}
                                </ChildMenuLink>
                                <ChildMenuLink href="/admission#exam">
                                    {dictionary.header.exam}
                                </ChildMenuLink>
                                <ChildMenuLink href="/admission#samples">
                                    {dictionary.header.samples}
                                </ChildMenuLink>
                                <ChildMenuLink href="/admission#documents">
                                    {dictionary.header.documents}
                                </ChildMenuLink>
                            </ChildMenuItem>
                        </ChildMenuList>
                    </ParentMenuItem>

                    <ParentMenuItem>
                        <ParentMenuLink href="/education-cost">
                            {dictionary.header.pricelist}
                        </ParentMenuLink>
                        <ChildMenuList>
                            <ChildMenuItem>
                                <ChildMenuLink href="/education-cost#price">
                                    {dictionary.header.price}
                                </ChildMenuLink>
                                <ChildMenuLink href="/education-cost#online">
                                    {dictionary.header.online}
                                </ChildMenuLink>
                                <ChildMenuLink href="/education-cost#bank-account">
                                    {dictionary.header.bankAccount}
                                </ChildMenuLink>
                            </ChildMenuItem>
                        </ChildMenuList>
                    </ParentMenuItem>
                    <ParentMenuItem>
                        <ParentMenuLink href="/success">
                            {dictionary.header.success}
                        </ParentMenuLink>
                        <ChildMenuList>
                            <ChildMenuItem>
                                <ChildMenuLink href="/success#student-success">
                                    {dictionary.header.studentSuccess}
                                </ChildMenuLink>
                                <ChildMenuLink href="/success#teacher-success">
                                    {dictionary.header.teacherSuccess}
                                </ChildMenuLink>
                                <ChildMenuLink href="/success#school-success">
                                    {dictionary.header.schoolSuccess}
                                </ChildMenuLink>
                                <ChildMenuLink href="/success#alumni">
                                    {dictionary.header.alumni}
                                </ChildMenuLink>
                            </ChildMenuItem>
                        </ChildMenuList>
                    </ParentMenuItem>
                    {isRegistrationItemVisible && (
                        <ParentMenuItem>
                            <ParentMenuLink
                                href={googleFormUrl}
                                target="_blank"
                            >
                                {dictionary.header.registration}
                            </ParentMenuLink>
                        </ParentMenuItem>
                    )}
                </ParentMenuList>
                <LanguageSelector />
                <RegistrationButton>
                    <ParentMenuLink href={googleFormUrl} target="_blank">
                        {dictionary.header.registration}
                    </ParentMenuLink>
                </RegistrationButton>
                <StyledBurger open={isOpen} onClick={() => setIsOpen(!isOpen)}>
                    <div />
                    <div />
                    <div />
                </StyledBurger>
            </Wrapper>
        </Container>
    );
}

export default Header;
