import styled from "styled-components";

export const Container = styled.div`
    height: 120px;
    color: black;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 68.75em) {
        height: 110px;
    }

    @media only screen and (max-width: 30em) {
        height: 100px;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1280px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 68.75em) {
        justify-content: flex-end;
        gap: 1rem;
        padding: 0 1rem;
    }
`;

export const Logo = styled.img`
    width: 100%;
`;

export const HomePageLink = styled.a`
    width: 12rem;

    @media only screen and (max-width: 68.75em) {
        position: absolute;
        left: 1rem;
    }

    @media only screen and (max-width: 22em) {
        width: 9rem;
    }
`;

export const StyledBurger = styled.div`
    width: 2rem;
    height: 2rem;
    z-index: 20;
    display: none;

    div {
        width: 2rem;
        height: 0.25rem;
        background-color: ${({ open }) => (open ? "#ccc" : "#333")};
        border-radius: 10px;
        transform-origin: 1px;
        transition: all 0.3s linear;
        &:nth-child(1) {
            transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
        }
        &:nth-child(2) {
            transform: ${({ open }) =>
                open ? "translateX(100%)" : "translateX(0)"};
            opacity: ${({ open }) => (open ? 0 : 1)};
        }
        &:nth-child(3) {
            transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
        }
    }

    @media only screen and (max-width: 68.75em) {
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
        top: 20px;
        right: 40px;
    }
`;

export const ParentMenuList = styled.ul`
    margin-left: auto;
    list-style-type: none;
    position: relative;

    @media only screen and (max-width: 68.75em) {
        flex-flow: column nowrap;
        background-color: white;
        position: fixed;
        transform: ${({ isOpen }) =>
            isOpen ? "translateX(0)" : "translateX(100%)"};
        top: 0;
        right: 0;
        height: 100vh;
        width: 200px;
        padding-top: 4rem;
        z-index: 20;
        transition: transform 0.3s ease-in-out;
    }

    @media only screen and (max-width: 30em) {
        display: flex;
        flex-direction: column;
    }
`;

export const ChildMenuList = styled.ul`
    display: none;
    position: absolute;
    z-index: 2;
`;

export const ParentMenuItem = styled.li`
    float: left;
    font-size: 0.85rem;
    font-weight: bold;
    padding: 57px 9px;

    &:hover {
        color: #10a74b;;
        cursor: pointer;
        transition: 0.5s;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    }

    &:hover ${ChildMenuList} {
        display: block;
        text-decoration: none;
        cursor: pointer;
        background-color: white;
        margin-top: 47px;
        text-align: left;
        margin-left: -9px;
    }

    @media only screen and (max-width: 68.75em) {
        padding: 20px 15px;
        transition: none;
        box-shadow: none;

        &:hover ${ChildMenuList} {
            display: none;
        }
    }
`;

export const ChildMenuItem = styled.li``;

export const ParentMenuLink = styled.a`
    color: inherit;
    text-decoration: none;
    padding: 57px 0;

    @media only screen and (max-width: 68.75em) {
        padding: 0;
    }
`;

export const ChildMenuLink = styled.a`
    color: black;
    text-decoration: none;
    display: block;
    text-transform: uppercase;
    padding: 20px 15px;
    &:hover {
        transition: 0.3s ease-out;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 25px 50px -12px;
        background-color: #ddd;
    }
`;

export const LanguageOption = styled.div`
    margin-left: auto;

    @media only screen and (max-width: 68.75em) {
        margin-left: 0;
    }
`;

export const FilterLanguageOption = styled.option``;

export const FilterLanguage = styled.select`
    margin-left: 10px;
    padding: 5px;
    border: none;
`;

export const RegistrationButton = styled.button`
    border: 1px #10a74b;
    padding: 12px 20px;
    cursor: pointer;
    color: white;
    background-color: #10a74b;;
    font-weight: 500;
    margin-left: 1rem;

    &:hover {
        color: #10a74b;;
        padding: 11px 19px;
        background-color: white;
        border: 1px solid #10a74b;;
        transition: transform 0.3s ease-in-out;
    }

    @media only screen and (max-width: 68.75em) {
        margin-left: 0;
    }

    @media only screen and (max-width: 30em) {
        display: none;
    }
`;

export const Link = styled.a`
    text-decoration: none;
    color: inherit;
`;
